import React, { useMemo, useState, useContext } from "react";
import styled from "styled-components";
import Column from "../../layout/Column.js";
import Row from "../../layout/Row.js";
import IconBlueCardsAdd from "../../../assets/icons/blue-cards-add.svg";
import IconBlueChat from "../../../assets/icons/blue-chat.svg";
import IconBlueLink from "../../../assets/icons/blue-link.svg";
import IconBlueCheckmark from "../../../assets/icons/blue-checkmark.svg";
import IconBlueQuestion from "../../../assets/icons/blue-question.svg";
import IconGlobe from "../../../assets/icons/globe.svg";
import IconFilm from "../../../assets/icons/film.svg";
import IconStar from "../../../assets/icons/star.svg";
import MorphemeGrid from "./MorphemeGrid.js";
import { isMobile } from "react-device-detect";
import { computeGlobalRank } from "lingoflix-shared/src/utils.js";
import { SettingsContext } from "../../ContextProvider.js";
import { getRecommendationAttributes } from "./Phrase.js";

const PopupContents = styled.h1`
  text-align: left;
`;

const Span = styled.h1`
  font-size: ${({ $isBig }) => (isMobile ? 1.1 : 1.625) * ($isBig ? 2 : 1)}rem;
  margin-bottom: 0.5rem;
  transition: font-size 0.5s ease-in-out;
  cursor: pointer;
`;

const Meaning = styled.p`
  font-size: 0.75rem;
  margin: 0;
  color: ${({ $primary }) => !$primary && "rgb(180, 180, 180)"};
  text-align: ${({ $center }) => $center && "center"};
`;

const AddIconLabel = styled.span`
  color: #0091ff;
  padding-left: 5px;
  font-size: 0.75rem;
`;

const AddIcon = styled(Row)`
  cursor: ${({ $disabled }) => !$disabled && "pointer"};
  opacity: ${({ $disabled }) => $disabled && 0.5};
`;

const AddIconImage = styled.img`
  width: 32px;
  height: 32px;
`;

function ToggleComponent({ a, b, tooltip }) {
  // Toggle between a and b on click
  const [isA, setIsA] = useState(true);
  return (
    <div onClick={() => setIsA(!isA)} title={tooltip}>
      {isA ? a : b}
    </div>
  );
}

function ExplicableIcon({ icon, tooltip, inlineText }) {
  return ToggleComponent({
    a: <img src={icon} alt={tooltip} width="16" height="16" />,
    b: inlineText,
    tooltip,
  });
}

export default function PhrasePopover({
  breakdown,
  getWordBreakdownKnowledgeState,
  setWordBreakdownKnowledgeState,
  setPopupState,
}) {
  const [settings] = useContext(SettingsContext);
  const [activeCharacterIndex, setActiveCharacterIndex] = useState(-1);

  const {
    knownStatus,
    isRecommendedForGlobalRank,
    isRecommendedForEpisodeRankPercentile,
    globalRankThreshold,
    episodeRankPercentileThreshold,
  } = useMemo(
    () =>
      getRecommendationAttributes(
        breakdown,
        settings,
        getWordBreakdownKnowledgeState,
      ),
    [breakdown, settings, getWordBreakdownKnowledgeState],
  );

  const hasCard = useMemo(
    () => knownStatus?.knowledgeType === "card",
    [knownStatus],
  );
  const isManualKnown = useMemo(
    () => knownStatus?.knowledgeType === "manual",
    [knownStatus],
  );
  const isUnknown = useMemo(() => !knownStatus, [knownStatus]);

  const lacksPronunciations = useMemo(
    () =>
      !breakdown ||
      !breakdown.characters ||
      breakdown.characters.every((c) => !c.pronunciation),
    [breakdown],
  );
  const showMorphemes = useMemo(
    () =>
      breakdown &&
      breakdown.morphemes &&
      breakdown.morphemes.some(
        (m) =>
          m.original &&
          breakdown.original &&
          breakdown.original.includes(m.original) &&
          !breakdown.characters.some((c) => c.original === m.original),
      ),
    [breakdown],
  );

  function addAnkiCard() {
    setPopupState({ breakdown, popup: "anki" });
  }

  function openChatPopup() {
    setPopupState({ breakdown, popup: "chat" });
  }

  const linkToJPDB = useMemo(
    () =>
      breakdown &&
      breakdown.jmdict &&
      breakdown.jmdict.jmdict_id &&
      breakdown.jmdict.original
        ? `https://jpdb.io/vocabulary/${breakdown.jmdict.jmdict_id}/${breakdown.jmdict.original}`
        : null,
    [breakdown],
  );

  const hasJMDictForm = !!(
    breakdown.jmdict &&
    (breakdown.jmdict.episode_wordrank || breakdown.jmdict.wordrank)
  );

  const hasOriginalForm = !!(
    (breakdown.wordfreq || breakdown.episode_wordrank) &&
    breakdown?.jmdict?.original !== breakdown?.original
  );

  const showRankData = hasOriginalForm || hasJMDictForm;
  const showRankFormColumn = true; //hasOriginalForm && hasJMDictForm;

  const highlightStar = (n, a, b) => (
    <ToggleComponent
      a={
        <>
          <img
            src={IconStar}
            alt="This Statistic cause the word to be highlighted"
            width="16"
            height="16"
            style={{ float: "left" }}
          />
          &nbsp;
          <span>{n}</span>
        </>
      }
      b={
        <div>
          {a} &le; {b} <br /> so it got highlighted
        </div>
      }
      tooltip="This statistic caused this word to be highlighted"
    />
  );

  const jdTableCell = (
    displayText,
    detailedText = null,
    highlight = false,
    threshold = null,
  ) => {
    displayText ??= "?";
    detailedText ??= "?";
    return (
      <td
        style={{
          fontSize: "0.75rem",
          borderBottom: "1px solid #888888",
          borderRight: "1px solid #888888",
        }}
      >
        <span style={{ display: "inline-block" }}>
          {highlight
            ? highlightStar(displayText, detailedText, threshold)
            : displayText}
        </span>
      </td>
    );
  };

  const rankTableRow = (word, enableGlow) => {
    const globalWordRank = computeGlobalRank(word);
    const episodeWordRankPercentile = word.episode_wordrank_percentile || null;

    const highlightGlobalRank = enableGlow && isRecommendedForGlobalRank;
    const highlightEpisodeRankPercentile =
      enableGlow && isRecommendedForEpisodeRankPercentile;

    const roundMultiple = (n, multiple) => {
      if (n === null) return null;
      return Math.ceil(n / multiple) * multiple;
    };

    const displayRank = (n) => {
      const rounded = roundMultiple(n, 100);
      if (rounded < 10_000) return rounded;
      return Math.ceil(rounded / 1_000) + "k";
    };

    const displayEpisodeRankPercentile = (n) => {
      if (n === null) return null;
      return roundMultiple(n, 5) + "%";
    };

    return (
      <tr>
        {showRankFormColumn && jdTableCell(word.original)}
        {jdTableCell(
          displayRank(globalWordRank),
          roundMultiple(globalWordRank, 100),
          highlightGlobalRank,
          globalRankThreshold,
        )}
        {jdTableCell(
          displayEpisodeRankPercentile(episodeWordRankPercentile),
          Math.ceil(episodeWordRankPercentile) + "%",
          highlightEpisodeRankPercentile,
          episodeRankPercentileThreshold + "%",
        )}
      </tr>
    );
  };

  return (
    <PopupContents>
      <Row $justifySpaceBetween $flexStart>
        <Column>
          {lacksPronunciations && breakdown.pronunciation && (
            <Meaning $center>{breakdown.pronunciation}</Meaning>
          )}
          {showRankData && (
            <table
              style={{
                fontSize: "1rem",
                borderTop: "1px solid #888888",
                borderLeft: "1px solid #888888",
                borderCollapse: "collapse",
                marginRight: "10px",
              }}
            >
              <thead>
                <tr>
                  {showRankFormColumn && jdTableCell("Form")}
                  {jdTableCell(
                    <ExplicableIcon
                      icon={IconGlobe}
                      tooltip="Approximate Global Rank"
                      inlineText={
                        <div>
                          Approx. <br /> Global <br /> Rank
                        </div>
                      }
                    />,
                  )}
                  {jdTableCell(
                    <ExplicableIcon
                      icon={IconFilm}
                      tooltip="Within-Episode Rank Percentile"
                      inlineText={
                        <div>
                          Within <br /> Episode <br /> Rank <br /> Percentile
                        </div>
                      }
                    />,
                  )}
                </tr>
              </thead>
              <tbody>
                {hasOriginalForm && rankTableRow(breakdown, false)}
                {hasJMDictForm && rankTableRow(breakdown.jmdict, true)}
              </tbody>
            </table>
          )}
          {!showMorphemes && (
            <Row style={{ marginBottom: "4px" }}>
              {(breakdown.characters || [breakdown]).map(
                (breakdown, wordIndex) => (
                  <Column
                    key={wordIndex}
                    style={{ marginRight: "1.1rem", cursor: "pointer" }}
                    onClick={() =>
                      setActiveCharacterIndex(
                        wordIndex === activeCharacterIndex ? -1 : wordIndex,
                      )
                    }
                  >
                    {!lacksPronunciations && (
                      <Meaning $center>
                        {breakdown.pronunciation
                          ? breakdown.pronunciation
                          : "\u00a0"}
                      </Meaning>
                    )}
                    <Span $isBig={activeCharacterIndex === wordIndex}>
                      {breakdown.original}
                    </Span>
                    {breakdown.translations &&
                      breakdown.alphabet === "kanji" &&
                      breakdown.translations.map((translation, i) => (
                        <Meaning key={i} $primary $center>
                          {translation}
                        </Meaning>
                      ))}
                    {breakdown.lone_translations &&
                      breakdown.alphabet === "kanji" &&
                      breakdown.lone_translations.map((translation, i) => (
                        <Meaning key={i} $center>
                          {translation}
                        </Meaning>
                      ))}
                  </Column>
                ),
              )}
            </Row>
          )}
          {showMorphemes && (
            <MorphemeGrid breakdown={breakdown} setPopupState={setPopupState} />
          )}
          {!showMorphemes &&
            breakdown.translations.map((translation, i) => (
              <Meaning key={i} $center>
                {translation}
              </Meaning>
            ))}
        </Column>
        <Column $gap="1rem" $width="150px">
          <AddIcon
            onClick={() => isUnknown && addAnkiCard()}
            $center
            $disabled={!isUnknown}
            title={
              hasCard
                ? "Cannot add a card for a word that already has a card"
                : isManualKnown
                ? "Cannot add a card for a word that is already known"
                : "Add a card to Anki"
            }
          >
            <AddIconImage src={IconBlueCardsAdd} alt="Add card" />
            <AddIconLabel>
              {hasCard ? "Card exists" : isManualKnown ? "Marked known" : "Add"}
            </AddIconLabel>
          </AddIcon>
          {isManualKnown && (
            <AddIcon
              $center
              onClick={() =>
                setWordBreakdownKnowledgeState(breakdown, {
                  knowledgeType: "manual",
                  known: false,
                })
              }
            >
              <AddIconImage src={IconBlueQuestion} alt="Checkmark" />
              <AddIconLabel>Mark Unknown</AddIconLabel>
            </AddIcon>
          )}
          {isUnknown && (
            <AddIcon
              $center
              onClick={() =>
                setWordBreakdownKnowledgeState(breakdown, {
                  knowledgeType: "manual",
                  known: true,
                })
              }
            >
              <AddIconImage src={IconBlueCheckmark} alt="Checkmark" />
              <AddIconLabel>Mark Known</AddIconLabel>
            </AddIcon>
          )}
          <AddIcon onClick={openChatPopup} $center>
            <AddIconImage src={IconBlueChat} alt="Chat" />
            <AddIconLabel>Chat</AddIconLabel>
          </AddIcon>
          {linkToJPDB && (
            <AddIcon $center onClick={() => window.open(linkToJPDB, "_blank")}>
              <AddIconImage src={IconBlueLink} alt="JPDB.io" />
              <AddIconLabel>JPDB.io</AddIconLabel>
            </AddIcon>
          )}
        </Column>
      </Row>
    </PopupContents>
  );
}
