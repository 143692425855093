import React, { useContext } from "react";
import {
  microsToShortTimestamp,
  logEvent,
} from "lingoflix-shared/src/utils.js";
import Tile from "../layout/Tile.js";
import TileProgressBar from "../layout/TileProgressBar.js";
import { ProgressContext } from "../ContextProvider";
import { useNavigate } from "react-router-dom";

export default function VideoTile({ video }) {
  const [progress] = useContext(ProgressContext);
  const videoProgress = progress && progress[video.id];
  const navigate = useNavigate();

  return (
    <Tile
      $backgroundImage={`/video/${video.path}/middle.webp`}
      onClick={() => {
        if (video.showUrl) {
          navigate(`/watch/${video.showUrl}/${video.url}`);
        } else {
          navigate(`/browse/${video.url}`);
        }
        logEvent("clicked-video-list-show");
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <span style={{ alignSelf: "flex-start" }}>
          {video.topTitle ? video.topTitle : ""}
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {video.title && <span>{video.title}</span>}
          {video.duration && (
            <span>{microsToShortTimestamp(video.duration)}</span>
          )}
        </div>
      </div>
      {videoProgress &&
      video.showUrl &&
      Number.isFinite(videoProgress) &&
      videoProgress >= 0.0 &&
      videoProgress <= 1.0 ? (
        <TileProgressBar>
          <span style={{ width: `${videoProgress * 100}%` }} />
        </TileProgressBar>
      ) : null}
    </Tile>
  );
}
