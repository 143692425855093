import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { postRequest } from "lingoflix-shared/src/utils.js";
import Page from "./layout/Page.js";
import Dialog from "./layout/Dialog.js";
import Checkmark from "../assets/icons/checkmark-round.svg";
import Header from "./layout/Header.js";
import BackButton from "./atoms/BackButton.js";
import { SessionContext } from "./ContextProvider.js";
import styled from "styled-components";
import { loginRequest } from "./Login.js";

const ErrorMessage = styled.div`
  color: #ff0000;
  background-color: #ffeeee;
  border: 1px solid #ff0000;
  border-radius: 4px;
  padding: 10px;
  margin-top: 20px;
`;

export default function Sent() {
  const navigate = useNavigate();
  const email = sessionStorage.getItem("user");
  const [errorMsg, setErrorMsg] = useState(null);
  const [token, setToken] = useState("");
  const [session, setSession] = useContext(SessionContext);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("token");
    if (code) {
      setToken(code);
      handleTokenSubmit({ preventDefault: () => {} }, code);
    }
    setIsLoading(false);
  }, [location.search]);

  useEffect(() => {
    if (session && session.name) {
      navigate("/");
    }
  }, [session, navigate]);

  const handleTokenSubmit = async (event, token) => {
    event.preventDefault();
    try {
      await postRequest("sso-callback", { token }, (response) => {
        if (!response || response.error) {
          setErrorMsg(`Network response was not ok: ${response?.error}`);
        } else {
          setSession(response);
          navigate("/");
        }
      });
    } catch (error) {
      console.error("Error:", error);
      setErrorMsg(`Network response was not ok: ${error}`);
    }
  };

  const handleTokenChange = (e) => {
    const newToken = e.target.value;
    setToken(newToken);
    if (newToken.length === 8) {
      handleTokenSubmit(e, newToken);
    }
  };

  if (isLoading) {
    return (
      <Page>
        <Dialog>
          <h1>Loading...</h1>
        </Dialog>
      </Page>
    );
  }

  return (
    <Page>
      <Header $left={<BackButton onClick={() => navigate("/")} />} />
      <Dialog>
        <h1>Log In</h1>
        <h3 className="icon">
          <img src={Checkmark} alt="checkmark" />
        </h3>
        {email && (
          <h3 className="help">
            Log in link sent to <span className="value">{email}</span>
          </h3>
        )}
        <p>
          If using the Home Screen App, you can now paste the Access Code you
          received by mail below:
        </p>
        <form onSubmit={(e) => handleTokenSubmit(e, token)}>
          <div className="input-row">
            <input
              type="text"
              name="token"
              placeholder="Enter Access Code to login"
              value={token}
              onChange={handleTokenChange}
            />
          </div>
          <div className="button-bar" style={{ marginTop: "2rem" }}>
            <button type="submit">Submit</button>
          </div>
        </form>
        {email && (
          <form onSubmit={(e) => loginRequest(e, setErrorMsg, navigate)}>
            <p>
              Didn&apos;t receive an email? Please check your spam folder. If
              the email is there, mark it as not spam. Thank you.
              <button className="flat" type="submit">
                Click here to resend
              </button>
            </p>
            <input type="hidden" name="user" value={email} />
          </form>
        )}
        {errorMsg ? <ErrorMessage>{errorMsg}</ErrorMessage> : null}
      </Dialog>
    </Page>
  );
}
