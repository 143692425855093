import React, { useEffect } from "react";
import { colors } from "../constants.js";
import styled, { css } from "styled-components";

const TextAreaContainer = styled.div`
  width: 100%;
  .auto-grow-textarea {
    background: ${({ $bgColor }) => $bgColor || colors.textBackground};
    color: ${({ $fgColor }) => $fgColor || "white"};
    &::placeholder {
      color: ${({ $placeholderColor }) => $placeholderColor || "white"};
    }
    box-shadow: inset 1 1 0 ${colors.textShadow};
    border-radius: 8px;
    width: 100%;
    padding: ${({ $padding }) => $padding || "1rem"};
    box-sizing: border-box;
    border: 1px solid transparent;
    font: inherit;
    font-size: ${({ $fontSize }) => $fontSize || "1rem"};
    resize: none;
    ${({ $minHeight }) =>
      $minHeight &&
      css`
        min-height: ${$minHeight};
      `}
    &:focus {
      outline: none;
      border-color: ${colors.primary};
    }

    ${({ $centerText }) =>
      $centerText &&
      css`
        text-align: center;
      `}

    ${({ $boldText }) =>
      $boldText &&
      css`
        font-weight: bold;
      `}
  }
`;

export default function AutoGrowTextArea({
  value,
  minHeight,
  maxHeight,
  focus = false,
  $fontSize = "1em",
  $padding = "1rem",
  $fgColor = "white",
  $bgColor = colors.textBackground,
  $placeholder = "",
  $placeholderColor = "white",
  $focus = false,
  $centerText = false,
  $boldText = false,
  ...props
}) {
  const ref = React.useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = "auto";
      let newHeight = ref.current.scrollHeight + 2;
      if (maxHeight) {
        newHeight = Math.min(maxHeight, newHeight);
      }
      if (minHeight) {
        newHeight = Math.max(minHeight, newHeight);
      }
      ref.current.style.height = `${newHeight}px`;
    }
  }, [value, minHeight, maxHeight]);

  useEffect(() => {
    if (focus && ref.current) {
      ref.current.focus();
    }
  }, [focus]);

  return (
    <TextAreaContainer
      $fontSize={$fontSize}
      $padding={$padding}
      $fgColor={$fgColor}
      $bgColor={$bgColor}
      $placeholderColor={$placeholderColor}
      $focus={$focus}
      $centerText={$centerText}
      $boldText={$boldText}
    >
      <textarea
        ref={ref}
        value={value}
        placeholder={$placeholder}
        {...props}
        className="auto-grow-textarea"
      />
    </TextAreaContainer>
  );
}
