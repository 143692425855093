import React from "react";
import Tiles from "../layout/Tiles.js";
import VideoTile from "./VideoTile.js";

function VideosList({ tiles, wrap }) {
  if (!tiles) {
    return <h1>No videos</h1>;
  }
  return (
    <Tiles $wrap={wrap}>
      {tiles.map((video, i) => (
        <VideoTile key={`${video.showUrl}-${video.url}-${i}`} video={video} />
      ))}
    </Tiles>
  );
}

export default VideosList;
