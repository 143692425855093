import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import CenterChild from "../layout/CenterChild.js";
import Loading from "../Loading.js";
import MultiSwiper from "../swiper/MultiSwiper.js";
import {
  isKatakana,
  getRequest,
  postRequest,
  escapeRegex,
  timestampToMicros,
} from "lingoflix-shared/src/utils.js";

function getIndicesOfSubstringInList(list, subStr) {
  let indices = [];
  for (let i = 0; i < list.length; i++) {
    let pos = list[i].indexOf(subStr);
    while (pos !== -1) {
      indices.push([i, pos]);
      pos = list[i].indexOf(subStr, pos + subStr.length);
    }
  }
  return indices;
}

function capitalizeKatakanaRomaji(node) {
  if (node.characters) {
    node.characters.forEach((c, i, arr) => {
      if (c.pronunciation && isKatakana(c.original)) {
        // Any string that ends with ー, must be katakana to be capitalized
        if (c.original !== "ー" || (i > 0 && isKatakana(arr[i - 1].original))) {
          c.pronunciation = c.pronunciation.toUpperCase();
        }
      }
    });
  }
  return node;
}

function addInferredDataToSubtitles(subtitles) {
  const properNouns = new Set();
  subtitles = subtitles.map((subtitle, i) =>
    addInferredDataToSubtitle(subtitle, i, properNouns),
  );

  const updateEpisodeFrequences = (getter, statTarget) => {
    const counts = {};
    subtitles.forEach((subtitle) => {
      subtitle.words.forEach((word) => {
        const value = getter(word);
        if (value) {
          counts[value] = (counts[value] ?? 0) + 1;
        }
      });
    });

    const numValues = Object.keys(counts).length;
    const total = Object.values(counts).reduce((a, b) => a + b, 0);
    const frequencies = {};
    Object.entries(counts).forEach(([value, count]) => {
      frequencies[value] = count / total;
    });

    const rankedList = Object.keys(frequencies).sort(
      (a, b) => frequencies[b] - frequencies[a],
    );
    const ranks = {};
    rankedList.forEach((value, i) => {
      ranks[value] = i + 1;
    });

    subtitles.forEach((subtitle) => {
      subtitle.words.forEach((word) => {
        const value = getter(word);
        if (value) {
          const target = statTarget(word);
          target.episode_wordfreq = frequencies[value];
          target.episode_wordrank = ranks[value];
          target.episode_wordrank_percentile =
            100.0 * (ranks[value] / Math.max(1, numValues));
        }
      });
    });
  };
  updateEpisodeFrequences(
    (word) => word?.original,
    (word) => word,
  );

  updateEpisodeFrequences(
    (word) => word?.jmdict?.jmdict_id,
    (word) => word?.jmdict,
  );

  return subtitles;
}

const numberKanjiChars =
  "零一二三四五六七八九" /* 0 - 9 */ +
  "点" /* decimal point */ +
  "十百千万亿兆京垓秭穰"; /* powers of ten */

function isKanjiNumber(word) {
  return (word || "")
    .trim()
    .split("")
    .every((c) => numberKanjiChars.includes(c));
}

function endsInClosingParenthesis(word) {
  return word.endsWith(")") || word.endsWith("）");
}
function startsWithOpeningParenthesis(word) {
  return word.startsWith("(") || word.startsWith("（");
}

// This function matches the words to the original text. Then, if the match
// succeeds, we create punctuation nodes for any text that might be between
// the words.
function addPunctuationNodes(breakdown) {
  // First, we normalize all the unicode (LLM sometimes converts half-width to full-width)
  const normalize = (s) => s.normalize("NFKC");

  // Next we match the words to the original text
  const numberComponent = "[\\.\\d０１２３４５６７８９]";
  const kanaOrSpace = "[ぁ-んァ-ヶ\\s]";
  const leftParen = "[（(]";
  const rightParen = "[）)]";
  const kanaParenthetical = `${leftParen}${kanaOrSpace}*${rightParen}`;

  function makeWordMatcher(word) {
    return (
      word
        .split("")
        .map((c) => {
          if (isKanjiNumber(c)) {
            return `(?:${escapeRegex(
              c,
            )}|${numberComponent}+|(?=${numberComponent}))`;
          }
          return `${escapeRegex(c)}`;
        })
        // Sometimes words have extra prolonged vowel markers in them, which the LLM
        // drops. If something looks like "山里(やまさと)" we may want to ignore the
        // parenthetical as ruby characters.
        .map((r) => `${r}ー?(?:\\s*${kanaParenthetical})?`)
        // Spaces inside of a word get ignored
        .join("\\s*")
    );
  }
  // Note: we have to do this trick because numbers can get turned into kanji, so
  // we we encounter a string of digits or decimal points, that can count as a
  // match if we're trying to match a number character. We also need to do the
  // look-ahead because sometimes something like 24 gets turned into three words
  // like: 24 -> 二 十 四

  let words = breakdown.words || [];

  let wordOriginals = words.map((w) => normalize(w.original));
  let captureGroup = "(.*?)";
  let wordRegex = new RegExp(
    "^" +
      captureGroup +
      wordOriginals.map(makeWordMatcher).join(captureGroup) +
      captureGroup +
      "$",
    "s", // (allow newlines)
  );

  let regexMatchResult = wordRegex.exec(normalize(breakdown.original));
  if (!regexMatchResult) {
    console.warn(
      `Could not match word originals ${wordOriginals.join("; ")} with ${
        breakdown.original
      }`,
    );
    return false;
  }

  // Then if the match succeeds, we create punctuation nodes for any text that
  // might be between the words.
  let groups = regexMatchResult.slice(1);

  let newWords = [];
  for (let i = 0; i < words.length + 1; i++) {
    let punctuationFound = groups[i];

    punctuationFound.split("\n").forEach((line, i, lines) => {
      let barePunctuation = line.trim();
      if (barePunctuation) {
        newWords.push({
          original: barePunctuation,
          pronunciation: "",
          is_punctuation: true,
          characters: [],
        });
      }
      if (i < lines.length - 1) {
        newWords[newWords.length - 1].has_new_line = true;
      }
    });

    if (i < words.length) {
      newWords.push(words[i]);
    }
  }

  // Finally if there were ruby characters that got counted as punctuation, we delete them.
  const removeRubyParentheticals = (word) => {
    if (!word.is_punctuation || !word.original) return word;
    const matchKanaParenthetical = new RegExp(kanaParenthetical, "g");
    word.original = word.original.replace(matchKanaParenthetical, "");
    return word;
  };
  newWords = newWords
    .map(removeRubyParentheticals)
    .filter((w) => w.original); /* Throw away empty-string words */

  breakdown.words = newWords;
  return true;
}

function fallbackSentenceDetection(breakdown) {
  // This is the (less reliable) version of the logic for when we failed to match
  // the words with the original text.

  // Sentences are *usually* separated by newlines, but sometimes we want to
  // merge a line with just a parenthetical with the next line if it doesn't
  // have one.
  const lines = breakdown.original.split("\n").filter((x) => x);
  const linesBroken = lines
    .map((line) => {
      // If the line starts with a parenthetical, we break it out, otherwise we leave `meta` empty.
      // Note: sometimes a line starts with a unicode left-to-right mark [U+200E], which we ignore.
      const match = line.match(/^\u200e?\s*[(（](.*?)[)）]+\s*(.*?)\s*$/);
      // Note the '+' is there to deal with cases like "（山里(やまさと)）"
      return match ? { meta: match[1], line: match[2] } : { meta: "", line };
    })
    .reduce((acc, current) => {
      // If the previous line was empty and this one has no meta, we merge them.
      if (acc.length > 0 && !acc[acc.length - 1].line && !current.meta) {
        acc[acc.length - 1].line = current.line;
        return acc;
      }
      acc.push(current);
      return acc;
    }, []);

  // Build our sentences:
  const sentences = linesBroken.map(({ meta, line }) =>
    meta ? `（${meta}） ${line}` : line,
  );
  if (breakdown.words && breakdown.words.length > 0) {
    breakdown.words.map(capitalizeKatakanaRomaji).forEach((child, i, words) => {
      child.index = i;
      const occurrences = getIndicesOfSubstringInList(
        sentences,
        child.original,
      );
      const childMatches = breakdown.words.filter(
        (c) => c.original === child.original,
      );
      if (occurrences.length === 0) {
        child.sentences = [
          i > 0 && words[i - 1].sentences.length
            ? words[i - 1].sentences[0]
            : 0,
        ];
      } else if (childMatches.length === occurrences.length) {
        const myIndex = breakdown.words
          .filter((c) => c.original === child.original)
          .indexOf(child);
        const match = occurrences[myIndex % occurrences.length];
        child.sentences = [match[0]];
      } else {
        child.sentences = occurrences.map((o) => o[-1]);
      }
    });
  }
}

function addInferredDataToSubtitle(breakdown, index, properNouns) {
  breakdown.index = index;
  breakdown.words ||= [];

  // Machine friendly timestamps:
  breakdown.start = timestampToMicros(breakdown.start_time);
  breakdown.end = timestampToMicros(breakdown.end_time);

  breakdown.words.map(capitalizeKatakanaRomaji);

  const createdPunctuation = addPunctuationNodes(breakdown);
  if (createdPunctuation) {
    // If our words matched, we can use straight-forward logic to give every
    // word a sentence index.
    let sentence = 0;
    for (let i = 0; i < breakdown.words.length; i++) {
      breakdown.words[i].sentences = [sentence];

      const wordOriginal = breakdown.words[i].original || "";
      const nextWordOriginal =
        (i < breakdown.words.length - 1 && breakdown.words[i + 1].original) ||
        "";
      if (
        (breakdown.words[i].has_new_line &&
          !(
            endsInClosingParenthesis(wordOriginal) &&
            !startsWithOpeningParenthesis(nextWordOriginal)
          )) || // Newlines don't count if they're between a meta and a word
        (startsWithOpeningParenthesis(nextWordOriginal) &&
          !endsInClosingParenthesis(wordOriginal)) // If we start with a parenthetical, we're in a new sentence
      ) {
        sentence++;
      }
    }
  } else {
    // If not, we use the old logic as a fallback (but it doesn't handle the
    // case where words are duplicated well.)
    fallbackSentenceDetection(breakdown);

    // Also we need to exploit is_parenthetical to add opening and closing parenthetical punctuation nodes.
    let newWords = [];
    const addPunctuation = (punctuation, i) => {
      newWords.push({
        original: punctuation,
        pronunciation: "",
        is_punctuation: true,
        is_parenthetical: true,
        sentences: breakdown.words[i].sentences,
      });
    };

    breakdown.words.forEach((word, i) => {
      let previousParenthetical =
        i > 0 && breakdown.words[i - 1].is_parenthetical;
      let currentParenthetical = word.is_parenthetical;
      let nextParenthetical =
        i < breakdown.words.length - 1 &&
        breakdown.words[i + 1].is_parenthetical;
      if (previousParenthetical && !currentParenthetical) {
        addPunctuation("（", i);
      }
      newWords.push(breakdown.words[i]);
      if (currentParenthetical && !nextParenthetical) {
        addPunctuation("）", i);
      }
    });
    breakdown.words = newWords;
  }
  breakdown.words.forEach((child) => {
    if (child.pos === "PROPN") {
      if (properNouns.has(child.original)) {
        child.hideKanjiTranslations = true;
      } else {
        properNouns.add(child.original);
      }
    }
  });

  // Mark references to duplicate jmdict entries across the subtitle:
  const jmdictIds = new Set();
  breakdown.words.forEach((word) => {
    const jmdictId = word?.jmdict?.jmdict_id;
    if (jmdictId) {
      if (jmdictIds.has(jmdictId)) {
        word.jmdict.is_duplicate = true;
      } else {
        jmdictIds.add(jmdictId);
      }
    }
  });

  return breakdown;
}

async function readMetadataAndBlobs(response) {
  const reader = response.body.getReader();
  let buffer = new Uint8Array(0);

  async function readJSONLine() {
    let result = "";
    while (!buffer.includes(10 /* newline character */)) {
      const { done, value } = await reader.read();
      buffer = new Uint8Array([...buffer, ...value]);
      if (done && !buffer.includes(10)) {
        return null;
      }
    }
    const index = buffer.indexOf(10);
    result = new TextDecoder("utf-8").decode(buffer.subarray(0, index));
    buffer = buffer.subarray(index + 1);
    return JSON.parse(result);
  }

  async function readChunk(length) {
    while (buffer.length < length) {
      const { done, value } = await reader.read();
      buffer = new Uint8Array([...buffer, ...value]);
      if (done && buffer.length < length) {
        return null;
      }
    }
    const chunk = buffer.subarray(0, length);
    buffer = buffer.subarray(length);
    return chunk;
  }

  const metadata = await readJSONLine();
  const objectUrls = [];
  for (let i = 0; i < metadata.length; i++) {
    const chunk = await readChunk(metadata[i].length);
    // Create a blob from the chunk:
    const objectUrl = URL.createObjectURL(
      new Blob([chunk], { type: metadata[i].type }),
    );
    objectUrls.push(objectUrl);
  }
  return { metadata, objectUrls };
}

export default function Movie() {
  const { show: showUrl, episode: episodeUrl } = useParams();
  const [episode, setEpisode] = useState(null);
  const [config, setConfig] = useState(null);
  const [fragments, setFragments] = useState(null);
  const [videoSizes, setVideoSizes] = useState(null);
  const [posterUrls, setPosterUrls] = useState(null);
  const [error, setError] = useState("");

  const [wordToKnowledgeState, setWordToKnowledgeState] = useState({});
  const [jmdictIdToKnowledgeState, setJmdictIdToKnowledgeState] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const changeMembership = (
    collection,
    knowledgeType,
    field,
    item,
    membership,
  ) => {
    if (membership) {
      if (collection[item]) {
        if (collection[item].knowledgeType !== knowledgeType) {
          console.error(
            `Attempting to change the knowledge type of ${item} from ${collection[item].knowledgeType} to ${knowledgeType}`,
          );
        }
        return collection;
      }
      return { ...collection, [item]: { knowledgeType, [field]: item } };
    } else {
      if (collection[item]) {
        if (collection[item].knowledgeType !== knowledgeType) {
          console.error(
            `Attempting to remove ${item} from ${knowledgeType} collection, but it is ${collection[item].knowledgeType}`,
          );
          return collection;
        }
        const newCollection = { ...collection };
        delete newCollection[item];
        return newCollection;
      }
      return collection;
    }
  };

  useEffect(() => {
    const fetchWordKnowledgeStates = async () => {
      try {
        const response = await getRequest("/api/known-words");
        const wordToKnowledgeState = {};
        const jmdictIdToKnowledgeState = {};
        response.known.forEach((knownItem) => {
          if (knownItem.jmdictId) {
            jmdictIdToKnowledgeState[knownItem.jmdictId] = knownItem;
          } else {
            wordToKnowledgeState[knownItem.word] = knownItem;
          }
        });
        setWordToKnowledgeState(wordToKnowledgeState);
        setJmdictIdToKnowledgeState(jmdictIdToKnowledgeState);
      } catch (e) {
        console.error("Error fetching known words and JMDict ids", e);
        toast.error("Error fetching known words and JMDict ids");
      }
    };
    fetchWordKnowledgeStates();
  }, [setWordToKnowledgeState, setJmdictIdToKnowledgeState]);

  const getJmdictId = (breakdown) =>
    breakdown?.jmdict_id ?? breakdown?.jmdict?.jmdict_id;

  const getWordBreakdownKnowledgeState = useCallback(
    (breakdown) => {
      const jmdictId = getJmdictId(breakdown);
      if (jmdictId) {
        return jmdictIdToKnowledgeState[jmdictId];
      }
      return wordToKnowledgeState[breakdown?.original];
    },
    [wordToKnowledgeState, jmdictIdToKnowledgeState],
  );

  const setJmdictIdKnowledgeState = useCallback(
    async (knowledgeType, jmdictId, known) => {
      try {
        if (knowledgeType === "manual") {
          // For cards, the caller is responsible for making or deleting the card.
          await postRequest("/api/known-words/set-jmdict-id", {
            jmdictId,
            known,
          });
        }
        setJmdictIdToKnowledgeState((knownJmdictIds) =>
          changeMembership(
            knownJmdictIds,
            knowledgeType,
            "jmdictId",
            jmdictId,
            known,
          ),
        );
      } catch (e) {
        console.error("Error setting known JMDict ID");
        toast.error("Error setting known JMDict ID");
      }
    },
    [jmdictIdToKnowledgeState, setJmdictIdToKnowledgeState],
  );
  const setWordKnowledgeState = useCallback(
    async (knowledgeType, word, known) => {
      if (wordToKnowledgeState[word]?.knowledgeType === "card") {
        toast.error(
          `Cannot mark words that have cards as ${known ? "known" : "unknown"}`,
        );
        return;
      }
      try {
        if (knowledgeType === "manual") {
          // For cards, the caller is for making or deleting the card.
          await postRequest("/api/known-words/set-word", {
            word,
            known,
          });
        }
        setWordToKnowledgeState((knownWords) =>
          changeMembership(knownWords, knowledgeType, "word", word, known),
        );
      } catch (e) {
        console.error("Error setting known word", e);
        toast.error("Error setting known word");
      }
    },
    [wordToKnowledgeState, setWordToKnowledgeState],
  );

  const setWordBreakdownKnowledgeState = useCallback(
    async (breakdown, knowledgeState) => {
      const jmdictId = getJmdictId(breakdown);
      if (jmdictId) {
        setJmdictIdKnowledgeState(
          knowledgeState.knowledgeType,
          jmdictId,
          knowledgeState.known,
        );
      } else {
        setWordKnowledgeState(
          knowledgeState.knowledgeType,
          breakdown.original,
          knowledgeState.known,
        );
      }
    },
    [setJmdictIdKnowledgeState, setWordKnowledgeState],
  );

  const fetchBreakdown = useCallback((episode) => {
    getRequest(`/breakdown/${episode.path}/combined.json`)
      .then((config) => {
        // If subtitles is an array, we treat it as the only possible breakdown, named "default"
        if (config.constructor === Array) {
          config = { default: "default", breakdowns: { default: config } };
        }
        for (let name of Object.keys(config["breakdowns"])) {
          config["breakdowns"][name] = addInferredDataToSubtitles(
            config["breakdowns"][name],
          );
        }

        setConfig(config);
      })
      .catch((e) => {
        console.error("ERROR fetching breakdown", e);
        setError("Error fetching breakdown");
      });
  }, []);

  useEffect(() => {
    if (!episode) {
      return;
    }
    const thumbsUrl = `/api/thumbs/${episode.path}`;
    fetch(thumbsUrl)
      .then(async (response) => {
        const { metadata, objectUrls } = await readMetadataAndBlobs(response);
        setFragments(metadata.map(({ name }) => name));
        setVideoSizes(metadata.map(({ videoSize }) => videoSize));
        setPosterUrls(objectUrls);
      })
      .catch((e) => {
        console.error(e);
      });

    return () => {
      (posterUrls || []).forEach((url) => URL.revokeObjectURL(url));
    };
  }, [episode]);

  useEffect(() => {
    const fetchEpisode = async () => {
      const episode = await getRequest(`/api/episode/${showUrl}/${episodeUrl}`);
      setEpisode(episode);
      fetchBreakdown(episode);
    };

    fetchEpisode().catch((e) => {
      console.error("ERROR fetching episode", e);
      setError("Error fetching episode");
    });
  }, [showUrl, episodeUrl]);

  return (
    <div style={{ display: "relative" }}>
      {!!error && (
        <CenterChild $full>
          <h2>{error}</h2>
        </CenterChild>
      )}
      {config &&
        fragments &&
        fragments.constructor === Array &&
        !error &&
        fragments.length &&
        posterUrls && (
          <MultiSwiper
            episode={episode}
            config={config}
            fragments={fragments}
            videoSizes={videoSizes}
            posterUrls={posterUrls}
            getWordBreakdownKnowledgeState={getWordBreakdownKnowledgeState}
            setWordBreakdownKnowledgeState={setWordBreakdownKnowledgeState}
            indicateReady={() => setIsLoading(false)}
          />
        )}
      {isLoading && !error && <Loading />}
    </div>
  );
}
