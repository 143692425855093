import React, { useState, useEffect, createContext } from "react";
import { getRequest } from "lingoflix-shared/src/utils.js";

export const SessionContext = createContext();
export const ProgressContext = createContext();
export const SettingsContext = createContext();

export function ContextProvider({ children }) {
  const [session, setSession] = useState(null);
  const [progress, setProgress] = useState(null);
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    getRequest("/api/login/session").then(setSession, () => setSession({}));
  }, []);

  useEffect(() => {
    if (session && session.name) {
      getRequest("/api/progress").then(setProgress, () => setProgress({}));
    }
  }, [session]);

  useEffect(() => {
    if (session && session.name) {
      getRequest("/api/settings").then(setSettings, () => setSettings({}));
    }
  }, [session]);

  return (
    <SessionContext.Provider value={[session, setSession]}>
      <ProgressContext.Provider value={[progress, setProgress]}>
        <SettingsContext.Provider value={[settings, setSettings]}>
          {children}
        </SettingsContext.Provider>
      </ProgressContext.Provider>
    </SessionContext.Provider>
  );
}
