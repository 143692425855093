import React from "react";
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import Login from "./Login.js";
import Sent from "./Sent.js";
import OAuthLogin from "./OAuthLogin.js";
import DeleteAccount from "./DeleteAccount.js";

const LOGIN_TARGET_COOKIE_LIFETIME_MS = 1000 * 60 * 60; // 1 hour

function SaveLoginTarget() {
  const loginTarget = window.location.href;
  const loginTargetExpires = Date.now() + LOGIN_TARGET_COOKIE_LIFETIME_MS;
  localStorage.setItem("loginTarget", loginTarget);
  localStorage.setItem("loginTargetExpires", loginTargetExpires);

  return <Navigate to="/" />;
}

export function popLoginTarget() {
  const loginTarget = localStorage.getItem("loginTarget");
  const loginTargetExpires = localStorage.getItem("loginTargetExpires");
  localStorage.removeItem("loginTarget");
  localStorage.removeItem("loginTargetExpires");

  if (loginTargetExpires && +loginTargetExpires <= Date.now()) {
    return null; // Expired
  }
  return loginTarget;
}

export default function LoginRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" index element={<Login />} />
        <Route path="/sent" element={<Sent />} />
        <Route path="/oauth2callback" element={<OAuthLogin />} />
        <Route path="/delete-account" element={<DeleteAccount />} />
        <Route path="*" element={<SaveLoginTarget />} />
      </Routes>
    </BrowserRouter>
  );
}
