import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
// eslint-disable-next-line import/extensions,import/no-unresolved
import { Swiper, SwiperSlide } from "swiper/react";
// eslint-disable-next-line import/extensions,import/no-unresolved
import "swiper/css/bundle";
import { SettingsContext } from "../ContextProvider.js";
import { getSetting } from "../Settings.js";
import styled from "styled-components";
import VideoBackground, {
  VIDEO_ASPECT_RATIO,
} from "../layout/VideoBackground.js";
import BreakdownOverlay from "../breakdown/BreakdownOverlay.js";
import PopoverManager from "../breakdown/phrase/PopoverManager.js";
import Popup from "../breakdown/Popup.js";
import AnkiCreator from "../breakdown/anki/AnkiCreator.js";
import ChatPopup from "../breakdown/ChatPopup.js";
import AnkiDeckPopup from "../breakdown/anki/AnkiDeckPopup.js";
import FeedbackPopup from "../breakdown/FeedbackPopup.js";
import IconPlay from "../../assets/icons/play.svg";
import { getRequest } from "lingoflix-shared/src/utils.js";
import SeekBar from "./SeekBar.js";

const Container = styled.div`
  overflow: hidden;
`;

const FlexWrapper = styled.div`
  display: flex;
  height: 100dvh;
`;

const FlexGrower = styled.div`
  flex-grow: 1;
`;

const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  background: url("${IconPlay}") no-repeat center;
  cursor: pointer;
  z-index: 15;
`;

const PosterBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: ${(props) => `url(${props.poster})`};
  background-size: cover;
  background-position: center;
  z-index: 5;
`;

export default function Scene({
  videoElement,
  episode,
  fragment,
  subtitle,
  sceneBoundaries,
  index,
  posterUrls,
  shouldClaimVideoElement,
  isStreaming,
  isActiveScene,
  isSoloScene,
  isVisibleScene,
  popoverState,
  setPopoverState,
  popupState,
  setPopupState,
  subtitlesVisible,
  setSubtitlesVisible,
  isSeekBarVisible,
  setIsSeekBarVisible,
  createCardCallback,
  breakdownMenuOptions,
  getWordBreakdownKnowledgeState,
  setWordBreakdownKnowledgeState,
  indicateReady,
  popoverHasScrollbars,
  setPopoverHasScrollbars,
  slidePrev,
  slideNext,
  slideNextInstantly,
  seekToScene,
}) {
  const [settings] = useContext(SettingsContext);

  const sceneRef = useRef(null);
  const videoContainerRef = useRef(null);
  const wasJustLoadedSoloScene = useRef(false);
  const wasJustVisibleScene = useRef(false);
  const breakdownOverlayRef = useRef(null);

  const [swiper, setSwiper] = useState();
  const [playFailed, setPlayFailed] = useState(false);
  const [videoHeight, setVideoHeight] = useState(0);
  const [emptySpace, setEmptySpace] = useState(0);
  const [breakdownScroll, setBreakdownScroll] = useState(0);
  const [videoUrl, setVideoUrl] = useState("");
  const [popoverButtons, setPopoverButtons] = useState({});
  const [breakdownMoving, setBreakdownMoving] = useState(false);

  const videoPath = `/video/${episode.path}/${fragment}.webm`;
  const videoFramePath = `/video/${episode.path}/${fragment}_first.webp`;

  const registerPopoverButton = useCallback(
    (phraseIndex, button) => {
      setPopoverButtons((prev) => ({ ...prev, [phraseIndex]: button }));
    },
    [setPopoverButtons],
  );
  const getPopoverButton = useCallback(
    (phraseIndex) => popoverButtons[phraseIndex],
    [popoverButtons],
  );

  function closePopups() {
    setPopupState(null);
  }

  const calculateVideoDimensions = useCallback(() => {
    const pageWidth = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    );
    const pageHeight = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0,
    );
    const videoWidth = pageWidth;
    const videoHeight =
      (videoWidth * VIDEO_ASPECT_RATIO.height) / VIDEO_ASPECT_RATIO.width;
    setVideoHeight(Math.min(videoHeight, pageHeight));
    const emptySpacePx = Math.max(0, pageHeight - videoHeight);
    const emptySpaceVh = (emptySpacePx / pageHeight) * 100;
    setEmptySpace(emptySpaceVh);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", calculateVideoDimensions);
    window.addEventListener("orientationchange", calculateVideoDimensions);
    calculateVideoDimensions();
    return () => {
      window.removeEventListener("resize", calculateVideoDimensions);
      window.removeEventListener("orientationchange", calculateVideoDimensions);
    };
  }, [calculateVideoDimensions]);

  const play = useCallback(
    (fromStart = false) => {
      if (!videoElement) return;
      if (fromStart) {
        videoElement.currentTime = 0;
      }
      var playPromise = videoElement.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            indicateReady();
            setPlayFailed(false);
          })
          .catch((error) => {
            indicateReady();
            setPlayFailed(true);
            console.error(error);
          });
        // Note: we call indicateReady() even if play() fails, because either way
        // we want to dismiss the video loading spinner.
      }
    },
    [videoElement, indicateReady],
  );

  const pause = useCallback(() => {
    if (videoElement) {
      videoElement.pause();
    }
  }, [videoElement]);

  const isVideoPlaying = useCallback(() => {
    const video = videoElement;
    return (
      video &&
      video.currentTime > 0 &&
      !video.paused &&
      !video.ended &&
      video.readyState > 2
    );
  }, [videoElement]);

  const togglePlay = useCallback(() => {
    if (isVideoPlaying()) {
      pause();
    } else {
      play();
    }
  }, [isVideoPlaying, pause, play]);

  // show/hide based on subtitlesVisible
  useEffect(() => {
    if (swiper) {
      if (subtitlesVisible) {
        swiper.slideTo(1);
      } else {
        swiper.slideTo(0);
      }
    }
  }, [subtitlesVisible, swiper]);

  // On iOS, assets are unloaded when the tab is not visible.
  // This is a workaround to reload the video and poster when the tab is visible again.
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        if (videoElement) {
          try {
            videoElement.load();
          } catch (error) {
            console.error("Error reloading video:", error);
          }
        }
        const posterBackgrounds = document.querySelectorAll(
          `[style*="background-image: url(${posterUrls?.[index]})"]`,
        );
        posterBackgrounds.forEach((element) => {
          element.style.backgroundImage = `url(${posterUrls?.[index]})`;
        });
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [episode.path, fragment]);

  useEffect(() => {
    const videoSource = `/video/${episode.path}/${fragment}.webm`;
    // Function to fetch the entire video file
    const fetchVideo = async () => {
      try {
        const response = await getRequest(videoSource, {}, null, true);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setVideoUrl(url);
      } catch (error) {
        console.error("Error fetching video:", error);
      }
    };

    if (isStreaming) {
      setVideoUrl(videoSource);
    } else {
      fetchVideo();
    }

    // Clean up the object URL when the component unmounts
    return () => {
      if (videoUrl && !isStreaming) {
        URL.revokeObjectURL(videoUrl);
      }
    };
  }, []);

  const appropriateVideoElement = useCallback(() => {
    const videoElementOwned =
      videoElement && videoElement.parentNode === videoContainerRef.current;
    if (videoElementOwned) return;
    if (!videoElement) return;

    const video = videoElement;

    if (videoContainerRef.current && !videoElementOwned && videoUrl) {
      // Make sure the video is configured correctly:
      video.playsInline = true;
      video.preload = "auto";
      video.poster = posterUrls?.[index];
      video.src = videoUrl;

      video.style.position = "absolute";
      video.style.top = 0;
      video.style.left = 0;
      video.style.width = "100%";
      video.style.height = "100%";

      video.style.zIndex = 10;

      // Move the video element to our container.
      videoContainerRef.current.appendChild(video);

      video.load();
    }
  }, [videoElement, videoContainerRef, videoUrl, posterUrls]);

  useEffect(() => {
    const videoElementOwned =
      videoElement && videoElement.parentNode === videoContainerRef.current;
    if (shouldClaimVideoElement) {
      appropriateVideoElement();
    }

    const isLoadedSoloScene = isSoloScene && videoUrl;
    if (isLoadedSoloScene && !wasJustLoadedSoloScene.current) {
      play();
    } else if (wasJustLoadedSoloScene.current && !isLoadedSoloScene) {
      pause();
      // Note: we're doing it this way so only the active scene (or one that was
      // just active) ever issues a play() or pause() call.
      // Otherwise we can get in trouble with a play call getting aborted by a pause call.
    }

    if (!isVisibleScene && wasJustVisibleScene.current) {
      if (videoElementOwned) {
        // Note: we need this timeout here. Just because the scene has just been
        // marked as invisible doesn't mean react has finished rerendering
        // things such that the video element is *actually* invisible.
        setTimeout(() => {
          videoElement.currentTime = 0;
        }, 10 /* 10 ms is effectively one frame (or two at high refresh rates) */);
      }
    }
    wasJustLoadedSoloScene.current = isLoadedSoloScene;
    wasJustVisibleScene.current = isVisibleScene;
  }, [
    isActiveScene,
    isSoloScene,
    shouldClaimVideoElement,
    isVisibleScene,
    videoElement,
    pause,
    play,
    appropriateVideoElement,
    videoUrl,
  ]);

  // Auto-advance feature:
  useEffect(() => {
    if (!videoElement) return;
    const handleVideoEnded = () => {
      const isEmpty = (subtitle?.words || []).length === 0;
      const shouldPause =
        getSetting(settings, "pauseBetweenScenes") &&
        (isEmpty
          ? getSetting(settings, "pauseBetweenScenesNoSubtitles")
          : true);

      if (!shouldPause) {
        slideNextInstantly();
      }
    };
    let videoEndedListenerAdded = false;
    if (isActiveScene) {
      videoElement.addEventListener("ended", handleVideoEnded);
      videoEndedListenerAdded = true;
    }

    return () => {
      if (videoEndedListenerAdded) {
        videoElement.removeEventListener("ended", handleVideoEnded);
      }
    };
  }, [subtitle, isActiveScene, slideNextInstantly, videoElement]);

  // Keyboard shortcuts
  useEffect(() => {
    function onKeyDown(e) {
      if (!isActiveScene || popupState) {
        return;
      }

      switch (e.code) {
        case "Space":
          e.preventDefault();
          togglePlay();
          break;
        case "ArrowUp":
          e.preventDefault();
          setSubtitlesVisible(true);
          break;
        case "ArrowDown":
          e.preventDefault();
          setSubtitlesVisible(false);
          break;
        case "ArrowLeft":
          e.preventDefault();
          if (
            videoElement &&
            (videoElement.currentTime > 1.0 || videoElement.ended)
          ) {
            play(true);
          } else {
            slidePrev();
          }
          break;
        case "ArrowRight":
          e.preventDefault();
          slideNext();
          break;
        default:
          break;
      }
    }

    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [
    isActiveScene,
    play,
    togglePlay,
    popupState,
    slideNext,
    slidePrev,
    setSubtitlesVisible,
    videoElement,
  ]);

  const updateSeekBarBottom = useCallback(() => {
    if (!isActiveScene) return;
    const pageWidth = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    );
    const videoWidth = pageWidth;
    const videoHeight =
      (videoWidth * VIDEO_ASPECT_RATIO.height) / VIDEO_ASPECT_RATIO.width;
    let seekBarBottom = videoHeight;
    if (breakdownOverlayRef.current) {
      const rect = breakdownOverlayRef.current.getBoundingClientRect();
      seekBarBottom = Math.min(rect.top, videoHeight);
    }
    if (sceneRef.current) {
      sceneRef.current.style.setProperty(
        "--seek-bar-bottom",
        `${seekBarBottom}px`,
      );
    }
  }, [isActiveScene]);

  useEffect(() => {
    if (isActiveScene) {
      updateSeekBarBottom();
    }
  }, [isActiveScene, updateSeekBarBottom]);

  useEffect(() => {
    if (breakdownMoving) {
      const interval = setInterval(() => {
        updateSeekBarBottom();
      }, 5);
      return () => clearInterval(interval);
    }
  }, [breakdownMoving, updateSeekBarBottom]);

  // Don't allow the video to play if there's a popup or popover open (it's
  // distracting, and auto-advance could trigger while interacting with the
  // popup)
  useEffect(() => {
    if (isActiveScene && (popupState || popoverState)) {
      pause();
    }
  }, [isActiveScene, popupState, popoverState, pause]);

  return (
    <Container ref={sceneRef}>
      <VideoBackground>
        <PosterBackground poster={posterUrls?.[index]} />
        <div ref={videoContainerRef}></div>
        {playFailed && <PlayButton onClick={togglePlay}></PlayButton>}
      </VideoBackground>
      {popupState?.popup === "anki" && isActiveScene && (
        <Popup title="Create Anki Card" onClose={closePopups}>
          <AnkiCreator
            videoPath={videoPath}
            posterPath={videoFramePath}
            posterBlobUrl={posterUrls?.[index]}
            episode={episode}
            parentBreakdown={subtitle}
            childBreakdown={popupState.breakdown}
            morpheme={popupState.morpheme}
            sceneNr={index}
            closeCallback={closePopups}
            createCallback={() => createCardCallback(index)}
            setWordBreakdownKnowledgeState={setWordBreakdownKnowledgeState}
          />
        </Popup>
      )}
      {popupState?.popup === "deck" && isActiveScene && (
        <Popup title="Anki Deck for this Scene" onClose={closePopups}>
          <AnkiDeckPopup movieId={episode.id} breakdownIndex={subtitle.index} />
        </Popup>
      )}
      {popupState?.popup === "feedback" && isActiveScene && (
        <Popup title="Feedback" onClose={closePopups}>
          <FeedbackPopup
            episode={episode}
            closePopup={closePopups}
            breakdown={subtitle}
          />
        </Popup>
      )}
      {popupState?.popup === "chat" && isActiveScene && (
        <ChatPopup
          episode={episode}
          videoPath={videoPath}
          parentBreakdown={subtitle}
          childBreakdown={popupState.breakdown}
          setPopupState={setPopupState}
        />
      )}
      <Swiper
        style={{ height: "100dvh" }} // Swiper bug: https://github.com/nolimits4web/swiper/issues/3599
        direction="vertical"
        keyboard={{ enabled: false }}
        threshold={50}
        onSwiper={setSwiper}
        onSetTranslate={() => {
          setBreakdownMoving(true);
          updateSeekBarBottom();
        }}
        onTransitionEnd={() => {
          setBreakdownMoving(false);
          updateSeekBarBottom();
        }}
        initialSlide={subtitlesVisible ? 1 : 0}
        onSlideChange={(swiper) => {
          if (isActiveScene) {
            setSubtitlesVisible(swiper.activeIndex === 1);
          }
        }}
        allowSlideNext={!popoverHasScrollbars}
        allowSlidePrev={!popoverHasScrollbars}
      >
        <SwiperSlide>
          <div style={{ height: "100dvh" }} onClick={togglePlay} />
        </SwiperSlide>
        <SwiperSlide>
          <FlexWrapper>
            <FlexGrower onClick={togglePlay}></FlexGrower>
            <BreakdownOverlay
              overlayRef={breakdownOverlayRef}
              episode={episode}
              breakdown={subtitle}
              breakdownMenuOptions={breakdownMenuOptions}
              isVisible={true}
              animation={{}}
              preferredHeight={emptySpace}
              videoHeight={videoHeight}
              getWordBreakdownKnowledgeState={getWordBreakdownKnowledgeState}
              setWordBreakdownKnowledgeState={setWordBreakdownKnowledgeState}
              setBreakdownScroll={setBreakdownScroll}
              popoverState={popoverState}
              setPopoverState={setPopoverState}
              popupState={popupState}
              setPopupState={setPopupState}
              registerPopoverButton={registerPopoverButton}
              popoverHasScrollbars={popoverHasScrollbars}
              isSeekBarVisible={isSeekBarVisible}
              setIsSeekBarVisible={setIsSeekBarVisible}
              onClick={togglePlay}
            />
          </FlexWrapper>
        </SwiperSlide>
      </Swiper>
      {isSeekBarVisible && (
        <div
          style={{
            position: "absolute",
            top: "calc(var(--seek-bar-bottom) - 30px)",
            height: "30px",
            width: "100%",
          }}
        >
          <SeekBar
            currentTime={sceneBoundaries[index].start}
            sceneBoundaries={sceneBoundaries}
            posterUrls={posterUrls}
            onSeekStart={pause}
            seekToScene={seekToScene}
          />
        </div>
      )}
      {isActiveScene && (
        <PopoverManager
          popoverState={popoverState}
          setPopoverState={setPopoverState}
          popupState={popupState}
          setPopupState={setPopupState}
          breakdown={subtitle}
          getPopoverButton={getPopoverButton}
          getWordBreakdownKnowledgeState={getWordBreakdownKnowledgeState}
          setWordBreakdownKnowledgeState={setWordBreakdownKnowledgeState}
          breakdownScroll={breakdownScroll}
          setPopoverHasScrollbars={setPopoverHasScrollbars}
        />
      )}
    </Container>
  );
}
