import styled, { css } from "styled-components";
import { colors } from "../constants.js";

export default styled.div`
  width: 20rem;
  background: rgba(32, 32, 32, 0.95);
  padding: 1rem;
  box-sizing: border-box; /* Padding should count towards the Card's size */
  border-radius: 1rem;
  box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.5);
  border: 1px solid transparent;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;

  ${({ $centerText, $toggled, $standAlone }) => css`
    ${$centerText && "text-align: center;"}
    ${$toggled && `border-color: ${colors.primary};`}
    ${$standAlone &&
    "position: absolute; width: 90%; max-width: 800px; max-height: 90%;"}
  `}
`;
