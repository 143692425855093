import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  postRequest,
  getRequest,
  binarySearch,
} from "lingoflix-shared/src/utils.js";
import BackButton from "../atoms/BackButton.js";
import Scene from "./Scene.js";
import styled from "styled-components";
import { ProgressContext } from "../ContextProvider.js";
import NumberIcon from "../NumberIcon.js";
import { isIOS } from "react-device-detect";
import IconCompress from "../../assets/icons/compress.svg";
import IconExpand from "../../assets/icons/expand.svg";

import IconSquare from "../../assets/icons/square.svg";
import IconSquareCheck from "../../assets/icons/square-check.svg";

const BackButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 2;
  width: 100%;
  min-height: 44px;
  cursor: pointer;
  pointer-events: none;
`;

const TopButtonsShow = styled.div`
  display: flex;
  position: absolute;
  left: ${({ $buttonsAreVisible }) =>
    $buttonsAreVisible
      ? "calc(2rem + 18px + 4rem)"
      : "0"}; // 18px BackButton width & 4rem FullscreenButton width
  width: ${({ $buttonsAreVisible }) =>
    $buttonsAreVisible
      ? "calc(100% - 2rem - 18px - 4rem - 4rem - 58px)"
      : "100%"};
  min-height: calc(2rem + 44px);
  z-index: 2;
  cursor: pointer;
`;

const FullscreenButton = styled.button`
  &.fullscreen-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    pointer-events: all;
    font-size: 2.5rem;
    font-weight: bold;
    color: white;
    outline: none;
  }
`;

const TopBarButtonWrapper = styled.div`
  padding: 1rem;
`;

function parseTimeLiberally(time) {
  if (!time) return false;

  let nonMicrosStr = "";
  let micros = 0;
  if (time.indexOf(".") != -1) {
    let dotSplit = time.split(".");
    if (dotSplit.length != 2) {
      return false;
    }
    nonMicrosStr = dotSplit[0];
    micros = parseInt(dotSplit[1].padEnd(6, "0"));
    if (micros === null) {
      return false;
    }
  } else {
    nonMicrosStr = time;
    micros = 0;
  }

  var components = nonMicrosStr
    .split(":")
    .map((x) => parseInt(x, 10))
    .reverse();
  var seconds = 0;
  for (let i = 0; i < components.length; i++) {
    if (components[i] === null) {
      return false;
    }
    seconds += components[i] * Math.pow(60, i);
  }

  return seconds * 1000000 + micros;
}

function getInt(numberString) {
  if (typeof numberString !== "string" || numberString.includes("_")) {
    return -1;
  }
  try {
    return parseInt(numberString, 10);
  } catch (e) {
    return -1;
  }
}

function FadeIn({ children }) {
  const divRef = useRef(null);
  setTimeout(() => {
    divRef.current.style.opacity = 1;
  }, 10);
  return (
    <div
      style={{ opacity: 0, transition: "opacity 0.5s ease-in-out" }}
      ref={divRef}
    >
      {children}
    </div>
  );
}

function getSceneBoundaries(subtitles, fragments) {
  const subBoundaries = [
    { start: 0, end: subtitles[0].start },
    ...subtitles.map((sub) => ({
      start: sub.start,
      end: sub.end,
    })),
    {
      start: subtitles[subtitles.length - 1].end,
      end: Math.ceil(subtitles[subtitles.length - 1].end * 1.05),
      // Note: in theory the breakdown ought to record how long any final
      // credits scene is, but right now it doesn't, so for now we assume it's 5%
      // of the length of the movie up to the last subtitle.
    },
  ];

  const sceneBoundaries = fragments.map((fragment, fragmentIndex) => {
    if (fragment.includes("_")) {
      const [left, right] = fragment.split("_");
      return {
        index: fragmentIndex,
        fragment: fragment,
        start: subBoundaries[parseInt(left)].end,
        end: subBoundaries[parseInt(right)].start,
      };
    }
    return {
      ...subBoundaries[parseInt(fragment)],
      index: fragmentIndex,
      fragment: fragment,
    };
  });
  return sceneBoundaries;
}

const SCENES_RENDERED_ON_EITHER_SIDE = 5;
const NUM_VIDEO_ELEMENTS_BEFORE = 1;
const NUM_VIDEO_ELEMENTS_AFTER = 1;
const NUM_VIDEO_ELEMENTS =
  1 + NUM_VIDEO_ELEMENTS_BEFORE + NUM_VIDEO_ELEMENTS_AFTER;

const VIDEO_SIZE_STREAMING_THRESHOLD = 1_000_000; // 1MB

export default function MultiSwiper({
  config,
  episode,
  fragments,
  videoSizes,
  posterUrls,
  getWordBreakdownKnowledgeState,
  setWordBreakdownKnowledgeState,
  indicateReady,
}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [breakdownIndexToCardCount, setBreakdownIndexToCardCount] =
    useState(null);

  const fragmentsReversed = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(fragments).map(([idx, frag]) => [frag, +idx]),
      ),
    [fragments],
  );

  const mainSwiper = useRef(null);
  const videoElementsRef = useRef([]);

  useEffect(() => {
    videoElementsRef.current = Array(NUM_VIDEO_ELEMENTS)
      .fill(null)
      .map(() => document.createElement("video"));
  }, []);

  const [progress, setProgress] = useContext(ProgressContext);
  const [numberOfCards, setNumberOfCards] = useState(null);

  // The active scene is whichever scene is currently visible.
  const [activeScene, setActiveScene] = useState(null);

  // The visible scenes are the (one or two) scenes that are visible on the screen.
  const [visibleScenes, setVisibleScenes] = useState([]);

  const [subtitlesVisible, setSubtitlesVisible] = useState(true);
  const [popoverState, setPopoverState] = useState(null);
  const [popoverHasScrollbars, setPopoverHasScrollbars] = useState(false);
  const [popupState, setPopupState] = useState(null);
  const [isVisibleTopButtons, setIsVisibleTopButtons] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [subtitle, setSubtitle] = useState({});
  const [initialLoad, setInitialLoad] = useState(true);

  const [isSeekBarVisible, setIsSeekBarVisible] = useState(false);

  const [activeBreakdown, setActiveBreakdown] = useState(config["default"]);
  const possibleBreakdowns = Object.keys(config["breakdowns"]);
  const subtitles = config.breakdowns[activeBreakdown];
  const sceneBoundaries = useMemo(
    () => getSceneBoundaries(subtitles, fragments),
    [subtitles, fragments],
  );

  useEffect(() => {
    if (breakdownIndexToCardCount === null) {
      getRequest(
        "/api/anki/episode_cards_nr_by_scene/" + episode.id,
        {},
        setBreakdownIndexToCardCount,
      );
    }
  }, []);

  const toggleVisibility = () => {
    setIsVisibleTopButtons(!isVisibleTopButtons);
  };
  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      setIsFullscreen(true);
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        // for Firefox
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        // for Safari
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        // for IE
        document.documentElement.msRequestFullscreen();
      } else {
        setIsFullscreen(false);
      }
    } else {
      setIsFullscreen(false);
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        // for Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        // for Safari
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        // for IE
        document.msExitFullscreen();
      } else {
        setIsFullscreen(true);
      }
    }
  };

  function createCardCallback(scene) {
    let breakdownIndex = getInt(fragments[scene]) - 1;

    if (breakdownIndex >= 0 && breakdownIndex < subtitles.length) {
      setBreakdownIndexToCardCount((current) => {
        if (current === null) return null;
        return {
          ...current,
          [breakdownIndex]: (current[breakdownIndex] ?? 0) + 1,
        };
      });
    }
  }

  const getDisplacementInfo = useCallback(() => {
    const swiper = mainSwiper.current;
    if (!swiper) return null;
    const activeScene = swiper?.activeIndex ?? null;
    if (activeScene === null) return null;
    const displacementPixels =
      -swiper.slidesGrid[activeScene] - swiper.translate;
    const displacement = displacementPixels / swiper.width;
    const displacementSign = Math.sign(displacement);
    let visibleScenes = [activeScene];
    if (displacementSign !== 0) {
      visibleScenes.push(activeScene + displacementSign);
    }
    return {
      activeScene,
      displacement,
      visibleScenes,
    };
  }, [mainSwiper]);

  const updateVisibleScenes = useCallback(() => {
    const result = getDisplacementInfo();
    if (result === null) return;
    const { displacement, visibleScenes } = result;
    setVisibleScenes(visibleScenes);

    // Note: This isn't abrupt because there's also a CSS transition smoothing
    // the opacity transition.
    document.documentElement.style.setProperty(
      "--overlay-panel-opacity",
      displacement === 0 ? 1 : 0,
    );
  }, [getDisplacementInfo]);

  useEffect(() => {
    const swiper = mainSwiper.current;
    if (!swiper) return;
    updateVisibleScenes();

    const handleSlideChange = () => {
      const newScene = swiper.activeIndex;
      setActiveScene(newScene);
      setPopupState(null);

      setProgress((progress) => ({
        ...progress,
        [episode.id]: swiper.progress,
        order: [
          episode.id,
          ...progress.order.filter((id) => id !== episode.id),
        ],
      }));
      postRequest("/api/progress", {
        episodeId: episode.id,
        progress: swiper.progress,
      });
    };

    swiper.on("slideChange", handleSlideChange);

    return () => {
      swiper.off("slideChange", handleSlideChange);
    };
  }, [episode, mainSwiper, setPopupState, setProgress]);

  function handleBackButton() {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate(`/browse/${episode.show.url}`);
    }
  }

  // Determine number of cards for scene on scene change
  useEffect(() => {
    let subIndex = getInt(fragments[activeScene]) - 1;
    if (
      subIndex >= 0 &&
      subIndex < subtitles.length &&
      breakdownIndexToCardCount !== null
    ) {
      setNumberOfCards(breakdownIndexToCardCount[subIndex] ?? 0);
    } else {
      setNumberOfCards(0);
    }
  }, [activeScene, breakdownIndexToCardCount, fragments, subtitles.length]);

  // Determine subtitle on scene change
  useEffect(() => {
    let titleComponents = [episode.title, episode.show.title];
    if (activeScene) {
      const subIndex = getInt(fragments[activeScene]) - 1;
      const subtitle =
        subIndex >= 0 && subIndex < subtitles.length
          ? subtitles[subIndex]
          : subIndex === -1
          ? { start_time: "00:00:00.000000" }
          : {
              start_time: subtitles[getInt(fragments[activeScene - 1]) - 1]
                ? subtitles[getInt(fragments[activeScene - 1]) - 1].end_time
                : "00:00:00.000000",
            };
      setSubtitle(subtitle);
      titleComponents.unshift(
        subtitle.start_time.split(".")[0].replace(/^0:/, ""),
      );
    }
    document.title = titleComponents.join(" - ");
  }, [activeScene, subtitles]);

  // Hide top buttons after 2.5 seconds
  useEffect(() => {
    let timer;
    if (isVisibleTopButtons) {
      timer = setTimeout(() => setIsVisibleTopButtons(false), 2500);
    }

    return () => clearTimeout(timer);
  }, [isVisibleTopButtons]);

  // Put the start time in the URL on scene change:
  useEffect(() => {
    if (subtitle.start_time) {
      window.history.replaceState(
        {},
        "", // See: https://developer.mozilla.org/en-US/docs/Web/API/History/replaceState
        `/watch/${episode.show.url}/${episode.url}?time=${subtitle.start_time}`,
      );
    }
  }, [episode, activeScene, subtitle]);

  // Set scene on page load based on URL or progress
  useEffect(() => {
    if (mainSwiper.current && initialLoad) {
      setInitialLoad(false);
      const startTime = searchParams.get("time");
      const startTimeMicros = parseTimeLiberally(startTime);
      const fragment =
        startTimeMicros !== false &&
        fragments[
          Math.max(
            0,
            binarySearch(sceneBoundaries, startTimeMicros, (x) => x.start).low,
          )
        ];
      const startScene =
        fragment && fragment in fragmentsReversed
          ? fragmentsReversed[fragment]
          : -1;
      // If we have progress for this episode, use it to set the scene.
      const hasProgress =
        progress &&
        episode.id in progress &&
        progress[episode.id] !== null &&
        !isNaN(progress[episode.id]);
      const newScene =
        startScene !== -1
          ? startScene
          : hasProgress
          ? Math.round(progress[episode.id] * fragments.length)
          : 0;
      if (newScene >= 0) {
        setActiveScene(newScene);
        mainSwiper.current.slideTo(newScene);
      }
    }
  }, [progress]);

  const breakdownSwitchOptions = useMemo(() => {
    return possibleBreakdowns.length > 1
      ? possibleBreakdowns.map((name) => ({
          title: name,
          label: (
            <span>
              <img
                src={name === activeBreakdown ? IconSquareCheck : IconSquare}
                width="16"
                height="16"
              />{" "}
              {name}
            </span>
          ),
          action: () => setActiveBreakdown(name),
        }))
      : [];
  }, [possibleBreakdowns, activeBreakdown]);

  useEffect(() => {
    document.documentElement.style.setProperty("--overlay-panel-opacity", 1);
    // Note: Some elements (like popups and popovers) are "overlays", which we
    // want to have fade out while a swipe happens and fade back in when the
    // slide is done moving.
    //
    // To implement this, we set the opacity of all overlays to 1 initially,
    // and then set it to 0 when a swipe starts and to 1 when the slide is done
    // moving.
  }, [activeScene]);

  const slidePrev = useCallback(() => {
    if (mainSwiper.current) {
      mainSwiper.current.slidePrev();
    }
  }, [mainSwiper]);

  const slideNext = useCallback(() => {
    if (mainSwiper.current) {
      mainSwiper.current.slideNext();
    }
  }, [mainSwiper]);

  const slideNextInstantly = useCallback(() => {
    if (mainSwiper.current) {
      mainSwiper.current.slideNext(0);
      // Note: 0 makes us instantly switch slides instead of doing an animation.
    }
  }, [mainSwiper]);

  const seekToScene = useCallback(
    (n) => {
      if (mainSwiper.current) {
        mainSwiper.current.slideTo(n);
      }
    },
    [mainSwiper],
  );

  return (
    <FadeIn>
      {!popupState?.popup && (
        <>
          <TopButtonsShow
            onClick={toggleVisibility}
            $buttonsAreVisible={isVisibleTopButtons}
          />
          <BackButtonWrapper>
            {isVisibleTopButtons && (
              <TopBarButtonWrapper>
                <BackButton onClick={handleBackButton} />
              </TopBarButtonWrapper>
            )}
            {!isIOS && isVisibleTopButtons && (
              <FullscreenButton
                className="fullscreen-button"
                onClick={toggleFullscreen}
              >
                <img
                  src={isFullscreen ? IconCompress : IconExpand}
                  width="30"
                  height="30"
                />
              </FullscreenButton>
            )}
            <div onClick={toggleVisibility} style={{ width: "100%" }}></div>
            {!!numberOfCards && (
              <TopBarButtonWrapper
                style={{
                  display: isVisibleTopButtons ? "block" : "none",
                }}
              >
                <NumberIcon
                  number={numberOfCards}
                  onClick={() =>
                    setPopupState({
                      popup: "deck",
                      breakdown:
                        getInt(activeScene) >= 1 &&
                        getInt(activeScene) <= subtitles.length
                          ? subtitles[getInt(activeScene) - 1]
                          : {},
                    })
                  }
                  style={{ padding: "1rem" }}
                  icon={"cards"}
                />
              </TopBarButtonWrapper>
            )}
            {!isVisibleTopButtons && (
              <div
                style={{
                  cursor: "pointer",
                  pointerEvents: "none",
                  width: "calc(5% + 4.5rem + 6px)",
                }}
              ></div>
            )}
          </BackButtonWrapper>
        </>
      )}
      <Swiper
        direction="horizontal"
        loop={false}
        spaceBetween={30}
        centeredSlides
        keyboard={{ enabled: false }}
        threshold={50}
        onSetTranslate={updateVisibleScenes}
        onSwiper={(swiper) => {
          mainSwiper.current = swiper;
        }}
        allowSlideNext={!popoverHasScrollbars}
        allowSlidePrev={!popoverHasScrollbars}
        onSlideChangeTransitionEnd={updateVisibleScenes}
      >
        {fragments.map((f, i) => (
          <SwiperSlide key={i} virtualIndex={i}>
            {Math.abs(i - activeScene) <= SCENES_RENDERED_ON_EITHER_SIDE && (
              <Scene
                videoElement={videoElementsRef.current[i % NUM_VIDEO_ELEMENTS]}
                fragment={f}
                subtitle={
                  getInt(f) >= 1 && getInt(f) <= subtitles.length
                    ? subtitles[getInt(f) - 1]
                    : {}
                }
                episode={episode}
                sceneBoundaries={sceneBoundaries}
                index={i}
                posterUrls={posterUrls}
                shouldClaimVideoElement={
                  i >= activeScene - NUM_VIDEO_ELEMENTS_BEFORE &&
                  i <= activeScene + NUM_VIDEO_ELEMENTS_AFTER
                }
                isStreaming={
                  videoSizes &&
                  videoSizes[i] &&
                  videoSizes[i] >= VIDEO_SIZE_STREAMING_THRESHOLD
                }
                isActiveScene={i === activeScene}
                isSoloScene={i === activeScene && visibleScenes.length === 1}
                isVisibleScene={visibleScenes && visibleScenes.includes(i)}
                indicateReady={indicateReady}
                popoverState={popoverState}
                setPopoverState={setPopoverState}
                popupState={popupState}
                popoverHasScrollbars={popoverHasScrollbars}
                setPopoverHasScrollbars={setPopoverHasScrollbars}
                setPopupState={setPopupState}
                subtitlesVisible={subtitlesVisible}
                setSubtitlesVisible={setSubtitlesVisible}
                isSeekBarVisible={isSeekBarVisible}
                setIsSeekBarVisible={setIsSeekBarVisible}
                createCardCallback={createCardCallback}
                breakdownMenuOptions={breakdownSwitchOptions}
                getWordBreakdownKnowledgeState={getWordBreakdownKnowledgeState}
                setWordBreakdownKnowledgeState={setWordBreakdownKnowledgeState}
                slidePrev={slidePrev}
                slideNext={slideNext}
                slideNextInstantly={slideNextInstantly}
                seekToScene={seekToScene}
              />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </FadeIn>
  );
}
