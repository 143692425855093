import React, { useState } from "react";
import styled from "styled-components";
import Page from "./layout/Page.js";
import Dialog from "./layout/Dialog.js";
import { toast } from "react-toastify";
import { postRequest } from "lingoflix-shared/src/utils.js";

const Title = styled.div`
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
  font-weight: bold;
`;

const StyledForm = styled.form`
  margin-bottom: 20px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  text-align: left;
  width: 100%;
`;

const SubmitButton = styled.button`
  cursor: pointer;
  width: 100%;
`;

export default function Tos() {
  const [agreed, setAgreed] = useState(false);
  const [allowedContent, setAllowedContent] = useState([]);

  const handleAcceptTos = async (e) => {
    e.preventDefault();
    if (!agreed) {
      toast.error("You didn't tick the box to agree to the Terms of Service");
      return;
    }
    try {
      const response = await postRequest("/accept-tos", { allowedContent });
      if (response.ok) {
        window.location.reload();
      } else {
        throw new Error("Failed to accept Terms of Service");
      }
    } catch (err) {
      toast.error(`Error while accepting the Terms of Service: ${err.message}`);
    }
  };

  const handleContentChange = (service) => {
    setAllowedContent((prev) =>
      prev.includes(service)
        ? prev.filter((item) => item !== service)
        : [...prev, service],
    );
  };

  return (
    <Page>
      <Dialog>
        <h1>Terms of Service</h1>
        <StyledForm onSubmit={handleAcceptTos}>
          <div>
            <p>
              <strong>Welcome to Our App!</strong>
            </p>
            <p>
              Before you dive in, we need you to review and agree to our Terms
              of Service. It won&apos;t take long, we promise!
            </p>
            <ol>
              <li>
                <Title>Experimental Nature of the Service:</Title>
                Please be aware that our app is currently in an experimental
                phase. We&apos;re constantly improving, and we appreciate your
                understanding and patience with any hiccups along the way.
              </li>
              <li>
                <Title>Content Subscription:</Title>
                Based on the content you&apos;re subscribed to, you&apos;ll see
                shows and movies from those sources. Even if you&apos;re not a
                subscriber to a service, you&apos;ll still be able to see
                content that&apos;s available to everyone. Choose the services
                you&apos;re subscribed to:
                <br />
                <br />
                <CheckboxContainer>
                  <input
                    type="checkbox"
                    id="netflixSubscriber"
                    checked={allowedContent.includes("netflix")}
                    onChange={() => handleContentChange("netflix")}
                  />
                  <label htmlFor="netflixSubscriber">
                    I&apos;m a paid subscriber to Netflix
                  </label>
                </CheckboxContainer>
                <CheckboxContainer>
                  <input
                    type="checkbox"
                    id="youtubeSubscriber"
                    checked={allowedContent.includes("youtube")}
                    onChange={() => handleContentChange("youtube")}
                  />
                  <label htmlFor="youtubeSubscriber">
                    I&apos;m a paid subscriber to YouTube
                  </label>
                </CheckboxContainer>
              </li>
              <li>
                <Title>Data Retention:</Title> We want to remind you that all
                activities within the app, including chat interactions, will be
                retained by our system. We value your privacy, so please do not
                share sensitive or personal information while using our
                services.
              </li>
              <li>
                <Title>Data Deletion:</Title> Your privacy is important to us.
                Should you decide to delete your account, rest assured that all
                associated data will also be removed from our servers.
              </li>
              <li>
                <Title>Feedback is Crucial:</Title> Encountered an issue or have
                a suggestion? Your feedback is invaluable to us! Please use the
                feedback function within the app to report any problems or offer
                suggestions for improvement.
              </li>
            </ol>
            <p>
              By clicking &quot;Agree,&quot; you acknowledge that you have read,
              understood, and agreed to these terms. Welcome aboard, and we hope
              you enjoy your experience!
            </p>
            <CheckboxContainer>
              <input
                type="checkbox"
                id="termsAgree"
                checked={agreed}
                onChange={(e) => setAgreed(e.target.checked)}
                required
              />
              <label htmlFor="termsAgree">
                I agree to the Terms of Service
              </label>
            </CheckboxContainer>
            <SubmitButton type="submit">Accept</SubmitButton>
          </div>
        </StyledForm>
      </Dialog>
    </Page>
  );
}
