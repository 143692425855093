import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { logEvent, postRequest } from "lingoflix-shared/src/utils.js";
import Button from "./Button.js";
import Row from "../layout/Row.js";
import ArrowIcon from "../../assets/icons/arrow-down.svg";

const DarkBackground = styled.div`
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.5); /* Black w/ opacity */
`;
const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  display: block;
  margin: 0 auto;
  margin-top: 20%;
  animation: ${rotate360} 0.5s linear infinite;
  transform: translateZ(0);
  border-top: 2px solid white;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  border-left: 2px solid black;
  background: transparent;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

export async function exportAnkiCards(cardIds) {
  logEvent("click-export-anki");
  return postRequest(
    "/anki-export",
    {
      cardIds,
    },
    async (response) => {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "lingoflix.apkg";
      a.click();
    },
    true,
  );
}

function AnkiExportButton({ cardIds }) {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    try {
      await exportAnkiCards(cardIds);
    } catch (error) {
      console.error("Export failed:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      onClick={() => handleClick()}
      style={{ float: "right", margin: "1rem 2rem" }}
      disabled={loading}
    >
      <Row $gap="1rem" $center>
        <img src={ArrowIcon} alt="arrow" height="1rem" width="1rem" />{" "}
        <div>{loading ? "Loading" : "Download all"}</div>
      </Row>
      {loading && <ShowLoader />}
    </Button>
  );
}

function ShowLoader() {
  return (
    <DarkBackground>
      <Spinner />
      <p>Preparing download..</p>
    </DarkBackground>
  );
}

export default AnkiExportButton;
